// shows/hides dropdown menu
// triggered onmouseover/onmouseleave
function showDropdown(x) {
	document.getElementById(x).style.display = 'block';
}
function hideDropdown(x) {
	document.getElementById(x).style.display = 'none';
}

// toggles mobile navigation
// triggered onclick
var showObject = false;
function toggleMobileNav(x) {
	showObject = !showObject;
	var objects = document.getElementsByClassName(x);

	if (showObject) {
		for (i = 0; i < objects.length; i++) {
			objects[i].style.display = 'block';
		}
	} else {
		for (i = 0; i < objects.length; i++) {
			objects[i].style.display = 'none';
		}
	}
}

// read more/read less on category overview pages
// triggered onclick
function showMore() {
	var moreText = document.getElementById('moreText');
	var moreButton = document.getElementById('moreButton');

	if (moreText.classList.contains('d-none')) {
		moreText.classList.remove('d-none');
		moreButton.innerHTML = 'Read less';
	} else {
		moreText.classList.add('d-none');
		moreButton.innerHTML = 'Read more';
	}
}

// trigger search button click on enter
function onClickSearch() {
	if (event.keyCode == 13) {
		document.getElementById('qnpNavSearchBtn').click();
	}
}

// listserv
function rfc() {
	var f = document.hdcreg;
	if (f.name.value == '') {
		alert('Please enter your name');
		return false;
	}
	if (f.email.value == '' || f.ver_email.value == '') {
		alert('Pleae enter your email and verify email address');
		return false;
	}
	if (f.email.value != f.ver_email.value) {
		alert('Verify email address does not match email address.');
		return false;
	}
	if (f.pw.value == '' || f.pwv.value == '') {
		alert('Please check that both your Password and verify Password address are entered');
		f.pw.focus();
		return false;
	}
	if (f.pw.value.toLowerCase() != f.pwv.value.toLowerCase()) {
		alert('The Password fields do not match');
		f.ver_pw.focus();
		return false;
	}
	if (!listCheck()) {
		alert('Please choose a list');
		return false;
	}
	return true;
}

function listCheck() {
	var ck = false;
	var f = document.hdcreg;
	for (var i = 0; i < f.length; i++) {
		if (f.elements[i].type == 'checkbox') {
			if (document.hdcreg.elements[i].checked == true) {
				ck = true;
				break;
			}
		}
	}
	return ck;
}

function listservepop() {
	window.open(
		'/dcs/ContentServer?pagename=QnetPublic/ListServe/ListServeF',
		'Window1',
		'menubar=no,width=460,height=360,toolbar=no'
	);
}
